<template>
  <v-card elevation="2" class="card">
    <div class="box">
      <v-row v-for="(_, key) in record" :key="key">
        <v-col cols="4">{{ _[0] }}</v-col>
        <v-col cols="8">
          <RippleLink
            v-if="key === 'contract_address'"
            :to="`/address/${_[1]}`"
          >
            {{ _[1] }}
          </RippleLink>
          <span v-else-if="Array.isArray(_[1])">
            <span v-for="(item, index) of _[1]">
              <RippleLink
                v-if="key === 'tokens'"
                :to="`/address/${
                  record.tx_type[1] == '资产官方购买' || 
                  record.tx_type[1] == '资产转账' || 
                  record.tx_type[1] == '资产转移'|| 
                  record.tx_type[1] == '资产增发'|| 
                  record.tx_type[1] == '资产增发'? 
                    info.event_details[0].contract.contract_address : record.contract_address[1]}/${item}`"
              >
                {{ item}}
              </RippleLink>
              <span v-else>{{ item }}</span>
            </span>
          </span>
          <span v-else>{{ _[1] }}</span>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { RippleLink } from '@/components';
import {
  YMD,
  YMDHms,
  getTransactionName,
  isAssetContract,
  getAssetType,
} from '@/utils';

export default {
  name: 'TransactionInfo',
  props: {
    record: Object,
    info: Object,
  },
  components: { RippleLink },
  data: () => ({
    model: {},
  }),
  methods: {
    getTransactionName,
    isAssetContract,
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 16px;
  .box {
    padding: 16px;
  }
}
</style>
