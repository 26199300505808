<template>
  <v-container>
    <v-card elevation="2" link to="/transactions">
      <v-card-title>
        <v-icon>mdi-arrow-left</v-icon>
        <span>交易详情</span>
      </v-card-title>
    </v-card>
    <v-card elevation="2" class="card">
      <div class="box">
        <v-row v-for="(_, key) in model" :key="key">
          <v-col cols="4">{{ _[0] }}</v-col>
          <v-col cols="8">
            <RippleLink v-if="key === 'number'" :to="`/blocks/${_[1]}`">
              {{ _[1] }}
            </RippleLink>
            <RippleLink v-else-if="key === 'block_number'" :to="`/blocks/${_[1]}`">
              {{ _[1] }}
            </RippleLink>
            <RippleLink v-else-if="key === 'from'" :to="`/address/${_[1]}`">
              {{ _[1] }}
            </RippleLink>
            <RippleLink v-else-if="key === 'to'" :to="`/address/${_[1]}`">
              {{ _[1] }}
            </RippleLink>
            <v-chip v-else-if="key === 'status'" :color="state[_[1]]" outlined>
              {{ _[1] }}
            </v-chip>
            <span v-else>{{ _[1] }}</span>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <TransactionInfo :record="record" :info="info" />
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { RippleLink } from '@/components';
import { Transaction } from '@/api';
import {
  YMDHms,
  getTransactionType,
  getTransactionName,
  isAssetContract,
  getAssetType,
  transactionDetails,
} from '@/utils';

import TransactionInfo from './components/TransactionInfo';

export default {
  name: 'Transaction',
  components: {
    RippleLink,
    TransactionInfo,
  },
  data: () => ({
    model: {},
    record: {},
    info: {},
    // type: '',
    state: { SUCCESS: 'green', FAIL: 'red', PENDING: 'orange', NOTFOUND: '' },
  }),
  mounted() {
    this.getTransaction(this.$route.params.hash);
  },
  computed: {
    ...mapState(['transaction']),
  },
  methods: {
    ...mapActions(['getTransaction']),
    getTransactionName,
    isAssetContract,
    getAssetType,
    transactionDetails,
    async getTransaction(hash) {
      const res = await Transaction.getTradingRecord(hash);
      if (!!!res) {
        return;
      }
      const record = res.trading_record;
      this.info = record;
      const type = getTransactionType(record);

      this.record = transactionDetails(record, type);

      this.model = {
        tx_hash: ['交易哈希', record.tx_hash],
        status: ['状态', record.status],
        block_number: ['所在区块', record.block_number],
        pended_at: ['出块时间', YMDHms(record.pended_at)],
        from: ['交易发起者', record.from],
        to: ['交易接收者', record.to],
        gas_to_decimal: ['手续费', record.gas_to_decimal],
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 16px;
  .box {
    padding: 16px;
  }
}
</style>
